//features/timeslot/timeslotSlice.tsx
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

export interface Timeslot {
  _id: string;
  slotTime: string;
  isAvailable: boolean;
}

export interface NewTimeslot {
  isAvailable: boolean;
}
export interface TimeslotState {
  timeslots: Timeslot[];
  loading: boolean;
  error: string | null;
}

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });
const initialState: TimeslotState = {
  timeslots: [],
  loading: false,
  error: null,
};

export const fetchTimeslot = createAsyncThunk<
  Timeslot[],
  undefined,
  { rejectValue: string }
>("timeslot/fetchTimeslot", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get("/api/timeslot");
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

export const updateIsAvailable = createAsyncThunk(
  "timeslot/updateIsAvailable",
  async (
    { timeslotId, data }: { timeslotId: string; data: NewTimeslot },
    { getState, rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      await axiosInstance.put(`/api/timeslot/${timeslotId}`, data, config);
      return { timeslotId, ...data }; // Return the updated data including the timeslot ID
    } catch (error: any) {
      return rejectWithValue(error.response?.data.message || error.message);
    }
  }
);

const timeslotSlice = createSlice({
  name: "timeslot",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeslot.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchTimeslot.fulfilled,
        (state, action: PayloadAction<Timeslot[]>) => {
          state.loading = false;
          state.timeslots = action.payload;
        }
      )
      .addCase(
        fetchTimeslot.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An error occured";
        }
      )
      .addCase(
        updateIsAvailable.fulfilled,
        (
          state,
          action: PayloadAction<{ timeslotId: string; isAvailable: boolean }>
        ) => {
          const index = state.timeslots.findIndex(
            (timeslot) => timeslot._id === action.payload.timeslotId
          );
          if (index !== -1) {
            state.timeslots[index].isAvailable = action.payload.isAvailable; // Update the isAvailable status of the timeslot
          }
        }
      );
  },
});

export default timeslotSlice.reducer;
