import React, { useState, useRef, useCallback } from "react";
import { GoogleMap, LoadScript, Autocomplete } from "@react-google-maps/api";

const libraries = ["places"];

const mapContainerStyle = {
  height: "400px",
  width: "100%",
};

const center = {
  lat: 25.276987,
  lng: 55.296249,
};

interface AddressDetails {
  name: string;
  street: string;
  city: string;
  country: string;
  postalCode: string;
  isDefault: boolean;
}

interface MapWithAutocompleteProps {
  onAddressSelect: (address: AddressDetails) => void;
}

const MapWithAutocomplete: React.FC<MapWithAutocompleteProps> = ({
  onAddressSelect,
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const onLoad = useCallback((mapInstance: google.maps.Map) => {
    setMap(mapInstance);
  }, []);

  const onPlaceChanged = () => {
    if (autocompleteRef.current !== null && map !== null) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry && place.geometry.location) {
        // Check if location exists
        const addressDetails: AddressDetails = {
          name: place.name || "",
          street: place.formatted_address || "",
          city: "",
          country: "",
          postalCode: "",
          isDefault: false,
        };
        console.log(place.address_components);
        // Extract city, country, and postal code from address components
        place.address_components?.forEach((component) => {
          if (component.types.includes("locality")) {
            addressDetails.city = component.long_name;
          } else if (component.types.includes("country")) {
            addressDetails.country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            addressDetails.postalCode = component.long_name;
          }
        });
        onAddressSelect(addressDetails);
        console.log("Address details:", addressDetails);
        console.log("Latitude:", place.geometry.location.lat());
        console.log("Longitude:", place.geometry.location.lng());

        map.panTo(place.geometry.location);
        map.setZoom(15);
      } else {
        console.log("No details available for the input: ", place.name);
      }
    }
  };
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_API_MAP || ""}
      libraries={libraries as any} // Convert to mutable type
    >
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={center}
        onLoad={onLoad}
      >
        <Autocomplete
          onLoad={(ref) => (autocompleteRef.current = ref)}
          onPlaceChanged={onPlaceChanged}
        >
          <input
            type="text"
            placeholder="Enter a location"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              left: "50%",
              marginLeft: "-120px",
            }}
          />
        </Autocomplete>
      </GoogleMap>
    </LoadScript>
  );
};

export default MapWithAutocomplete;
