// src/features/promocode/promoCodeSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import {
  NewPromoCode,
  PromoCode,
  PromoCodeState,
  PromoCodeStatus,
} from "data/types";

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const initialState: PromoCodeState = {
  promocodes: [],
  loading: false,
  error: null,
};

// Assuming your backend endpoints follow similar patterns to the user endpoints
export const fetchPromoCodes = createAsyncThunk<
  PromoCode[],
  undefined,
  { rejectValue: string }
>("promoCode/fetchPromoCode", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get("/api/promocode");
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

export const createPromoCode = createAsyncThunk<
  PromoCode,
  NewPromoCode,
  { rejectValue: string }
>("promoCode/createPromoCode", async (promoCodeData, { rejectWithValue }) => {
  try {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");

    // Inside your createPromoCode thunk
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Access token from localStorage
      },
    };
    // Use `config` in your axios request

    const response = await axiosInstance.post(
      "/api/promocode",
      promoCodeData,
      config
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

// Add this thunk to your promoCodeSlice.ts
export const deletePromoCode = createAsyncThunk<
  string, // Assuming the backend returns the ID of the deleted promocode as confirmation
  string, // The type of the payload is the ID of the promocode to delete
  { rejectValue: string }
>("promocode/deletePromoCode", async (promocodeId, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axiosInstance.delete(
      `/api/promocode/${promocodeId}`,
      config
    );
    return promocodeId; // Return the ID of the deleted promocode
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});
export const editPromoCode = createAsyncThunk<
  string, // Assuming the backend returns the ID of the edited promocode as confirmation
  { promocodeId: string; data: NewPromoCode },
  { rejectValue: string }
>(
  "promocode/editPromoCode",
  async ({ promocodeId, data }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Set Content-Type header for JSON data
        },
      };
      const response = await axiosInstance.put(
        `/api/promocode/${promocodeId}`,
        data, // Send the updated promocode data
        config
      );
      return promocodeId; // Return the ID of the edited promocode
    } catch (error: any) {
      return rejectWithValue(error.response?.data.message || error.message);
    }
  }
);

// Add this interface above your createAsyncThunk definition
interface UpdateIsActiveArgs {
  promocodeId: string;
  isActive: boolean;
}
export const updateIsActive = createAsyncThunk(
  "promocode/updateIsActive",
  async (
    { promocodeId, data }: { promocodeId: string; data: PromoCodeStatus },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axiosInstance.put(
        `/api/promocode/${promocodeId}/status`,
        data,
        config
      );
      if (response.status !== 200) {
        throw new Error("Failed to update isActive status");
      }
      // Make sure to return the structure that matches your expectations
      return { promocodeId, ...data };
    } catch (error: any) {
      return rejectWithValue(error.response?.data.message || error.message);
    }
  }
);

const promoCodeSlice = createSlice({
  name: "promocode",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromoCodes.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchPromoCodes.fulfilled,
        (state, action: PayloadAction<PromoCode[]>) => {
          state.loading = false;
          state.promocodes = action.payload;
        }
      )
      .addCase(
        fetchPromoCodes.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An error occurred";
        }
      )

      .addCase(
        createPromoCode.fulfilled,
        (state, action: PayloadAction<PromoCode>) => {
          // Optionally update state to include the new promocode
          state.promocodes.push(action.payload);
        }
      )

      // Inside extraReducers of your promocodeSlice
      .addCase(
        deletePromoCode.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.promocodes = state.promocodes.filter(
            (promoCodeItem) => promoCodeItem._id !== action.payload
          );
        }
      )
      .addCase(
        editPromoCode.fulfilled,
        (state, action: PayloadAction<string>) => {
          // Optionally, update the state if needed
        }
      )
      .addCase(
        updateIsActive.fulfilled,
        (
          state,
          action: PayloadAction<{ promocodeId: string; isActive: boolean }>
        ) => {
          const index = state.promocodes.findIndex(
            (promocode) => promocode._id === action.payload.promocodeId
          );
          if (index !== -1) {
            state.promocodes[index].isActive = action.payload.isActive;
          }
        }
      );

    // Handle pending and rejected cases as needed

    // Handle pending and rejected cases as needed
  },
});

export default promoCodeSlice.reducer;
