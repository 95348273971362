import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchTimeslot } from "features/timeslot/timeslotSlice";
import { fetchServices } from "features/service/serviceSlice";
import { fetchProducts } from "features/product/productSlice";
import ServiceCard from "components/ServiceCard";
import PackageCard from "components/PackageCard";
import ProductCard from "components/ProductCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Address, VehicleInformation } from "data/types";
import { createOrder } from "features/orders/orderSlice";
import { clearCart } from "features/cart/cartSlice";
import {
  addNewAddress,
  addNewVehicle,
  fetchAddress,
  fetchVehicle,
  updateAddressDefault,
  updateVehicleDefault,
} from "features/user/userSlice";
import AddressForm from "components/AddressForm";
import Modal from "shared/Modal/Modal";
import MapWithAutocomplete from "components/MapWithAutocomplete";
import { fetchPromoCodes } from "features/promocode/promoCodeSlice";
import { useNavigate } from "react-router-dom";
import { Elements, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import VehicleForm from "components/VehicleForm";

export interface Timeslot {
  _id: string;
  slotTime: string;
  isAvailable: boolean;
}

interface BookingDetail {
  address: string;
  service: string;
  product: string;
  dateTime: string;
}
interface AddressInfo {
  addresses: Address[];
}

interface CartItem {
  id: string;
  type: "Package" | "Service" | "Product";
  name: string;
  price: number;
  quantity: number;
}

interface OrderData {
  car: VehicleInformation | null;
  date: Date | null;
  time: Timeslot | null;
  cartItems: CartItem[];
  address: Address | null;
  subtotal: number;
  discount: number;
  total: number;
}

let addressInfo: AddressInfo | null;

const CartScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [isNewAddress, setIsNewAddress] = useState(false); // Track new address
  const [isNewVehicle, setIsNewVehicle] = useState(false);

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot | null>(
    null
  );
  const [newAddress, setNewAddress] = useState<Address>({
    name: "",
    street: "",
    city: "",
    country: "UAE",
    postalCode: "",
    isDefault: false,
  });
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);
  const [isVehicleModalOpen, setIsVehicleModalOpen] = useState<boolean>(false);
  const { items } = useAppSelector((state) => state.cart);
  console.log(items);
  const packagesInCart = items.filter((item) => item.type === "Package");
  const servicesInCart = items.filter((item) => item.type === "Service");
  const productsInCart = items.filter((item) => item.type === "Product");

  const [promoCodeInput, setPromoCodeInput] = useState("");

  const [discount, setDiscount] = useState(0);
  const calculateSubtotal = (cartItems: CartItem[]) => {
    let subtotal = 0;
    cartItems.forEach((item) => {
      subtotal += item.price * item.quantity;
    });
    return subtotal;
  };

  const openAddressModal = () => setIsAddressModalOpen(true);
  const closeAddressModal = () => setIsAddressModalOpen(false);

  const openVehicleModal = () => setIsVehicleModalOpen(true);
  const closeVehicleModal = () => setIsVehicleModalOpen(false);

  const handleSaveNewAddress = async (address: Address) => {
    console.log("New Address", address);
    delete address._id;
    dispatch(addNewAddress(address));
    closeAddressModal();
    setIsNewAddress(false);
  };

  const handleCancelNewAddress = () => {
    setIsNewAddress(false);
  };

  const handleAddressSelect = (addressDetails: Address) => {
    setNewAddress(addressDetails);
  };

  const handleAddVehicle = () => {
    setIsNewVehicle(true);
  };

  useEffect(() => {
    const subtotalValue = calculateSubtotal(items);
    setSubtotal(subtotalValue);

    const totalValue = subtotalValue - discount;
    setTotal(totalValue);
  }, [items, discount]);

  const [subtotal, setSubtotal] = useState(calculateSubtotal(items));
  const [total, setTotal] = useState(subtotal);

  const { userInfo, addressInfo, vehicleInfo } = useAppSelector(
    (state) => state.users
  );

  const { promocodes } = useAppSelector((state) => state.promocodes);

  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [selectedVehicle, setSelectedVehicle] =
    useState<VehicleInformation | null>(null);

  const {
    orders,
    loading: loadingOrder,
    error: errorOrder,
  } = useAppSelector((state) => state.orders);

  useEffect(() => {
    if (addressInfo) {
      const defaultAddress = addressInfo.find((address) => address.isDefault);
      setSelectedAddress(defaultAddress || null);
    }
    if (vehicleInfo) {
      const defaultVehicle = vehicleInfo.find((vehicle) => vehicle.isDefault);
      setSelectedVehicle(defaultVehicle || null);
    }
  }, [addressInfo, vehicleInfo]);

  const { timeslots, loading, error } = useAppSelector(
    (state) => state.timeslots
  );

  const {
    services,
    loading: loadingServices,
    error: errorServices,
  } = useAppSelector((state) => state.services);

  const {
    products,
    loading: loadingProducts,
    error: errorProducts,
  } = useAppSelector((state) => state.products);

  useEffect(() => {
    dispatch(fetchTimeslot());
    dispatch(fetchServices());
    dispatch(fetchProducts());
    dispatch(fetchAddress());
    dispatch(fetchVehicle());
    dispatch(fetchPromoCodes());
  }, [dispatch]);

  const initialNewVehicle: VehicleInformation = {
    _id: "",
    make: "",
    model: "",
    year: 0,
    licensePlateNumber: "",
    isDefault: false,
  };

  const handleSaveNewVehicle = async (vehicle: VehicleInformation) => {
    delete vehicle._id;
    dispatch(addNewVehicle(vehicle));
    setIsNewVehicle(false);
  };

  const handleCancelNewVehicle = () => {
    setIsNewVehicle(false);
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAddress = addressInfo?.find(
      (address) => address._id === e.target.value
    );
    setSelectedAddress(selectedAddress || null);
    dispatch(updateAddressDefault(selectedAddress?._id || ""));
  };
  const handleVehicleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedVehicle = vehicleInfo?.find(
      (vehicle) => vehicle._id === e.target.value
    );
    setSelectedVehicle(selectedVehicle || null);
    dispatch(updateVehicleDefault(selectedVehicle?._id || ""));
  };

  const handleSelectTimeslot = (timeslot: Timeslot) => {
    if (selectedTimeslot && selectedTimeslot._id === timeslot._id) {
      setSelectedTimeslot(null); // Deselect if the same timeslot is clicked again
    } else {
      setSelectedTimeslot(timeslot); // Select the new timeslot
    }
  };

  const applyPromoCode = () => {
    const promoCode = promocodes.find(
      (code) => code.code === promoCodeInput.toUpperCase()
    );

    if (!promoCode || !promoCode.isActive) {
      alert("Invalid or expired promo code");
      return;
    }

    let discount = 0;
    if (promoCode.discountType === "FIXED") {
      discount = promoCode.discountValue;
    } else if (promoCode.discountType === "PERCENTAGE") {
      discount = subtotal * (promoCode.discountValue / 100);
    }

    setDiscount(discount);
    setTotal(subtotal - discount);
  };

  const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
  );
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const orderData = {
    car: selectedVehicle,
    date: selectedDate,
    time: selectedTimeslot,
    cartItems: items,
    address: selectedAddress,
    subtotal,
    discount,
    total,
  };

  const handlePayment = async () => {
    try {
      if (
        !selectedTimeslot ||
        !selectedDate ||
        !selectedAddress ||
        !selectedVehicle
      ) {
        alert(
          "Please select a timeslot, date, address, and vehicle before proceeding to payment."
        );
        return;
      }
      // Dispatch createOrder action to create the order
      const createdOrder = await dispatch(createOrder(orderData));

      let orderId: string | undefined;

      // Check if createdOrder.payload is defined and is of type Order
      if (createdOrder.payload && typeof createdOrder.payload === "object") {
        orderId = createdOrder.payload._id;
      } else {
        console.error("Error: Unable to retrieve order ID");
        return;
      }

      // Pass the order ID or order data to the payment endpoint
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/payment/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderId: orderId,
            items: items.map((item) => ({
              name: item.name,
              price: item.price,
              quantity: item.quantity,
            })),
          }),
        }
      );

      const data = await response.json();
      console.log("Data", data); // Log the data received from the backend

      // Redirect to the payment URL if it's not undefined
      if (data.url) {
        window.location = data.url;
      } else {
        console.error("Error: Payment URL is undefined");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    }
  };
  return (
    <div className="container mx-auto flex w-full min-h-screen ">
      <div className="w-4/6 p-4 text-white p-2 rounded-lg bg-slate-950 mr-2">
        <label
          htmlFor="bookingDetails"
          className="block text-xxl font-bold text-white mt-4 text-center "
        >
          <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            Customize Your Booking
          </span>
        </label>
        <div className="text-center text-yellow-500">
          Set details before proceeding to pay: Car,Address,Timeslot etc
        </div>
        <div className="flex items-center mt-4 space-x-4">
          <div>
            <label
              htmlFor="carMake"
              className="block text-lg font-medium text-white mb-1"
            >
              Select Car
            </label>
            <div className="inline-flex items-center space-x-4">
              <select
                id="carMake"
                value={selectedVehicle?._id}
                className="mt-1 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                onChange={handleVehicleChange}
              >
                {vehicleInfo?.map((vehicle) => (
                  <option key={vehicle._id} value={vehicle._id}>
                    {vehicle.make} - {vehicle.model} - {vehicle.year}
                  </option>
                ))}
              </select>
              <ButtonPrimary
                className="mt-1 whitespace-nowrap"
                onClick={openVehicleModal}
              >
                Add Car
              </ButtonPrimary>
              {isVehicleModalOpen && (
                <Modal isOpen={isVehicleModalOpen} onClose={closeVehicleModal}>
                  <div className="m-4">
                    <VehicleForm
                      initialValues={initialNewVehicle}
                      onSubmit={handleSaveNewVehicle}
                      onCancel={handleCancelNewVehicle}
                    />
                  </div>
                </Modal>
              )}
            </div>
          </div>
        </div>

        <div className="mt-4 ">
          <label
            htmlFor="date"
            className="block text-lg font-medium text-white mt-4 "
          >
            Select Date{" "}
          </label>
          <DatePicker
            selected={selectedDate}
            onChange={(date: Date) => setSelectedDate(date)}
          />
        </div>
        <div className="mt-4">
          <label
            htmlFor="timeSlot"
            className="block text-lg font-semibold text-white mt-4"
          >
            Select Timeslot
          </label>
          <div className="flex mt-4 space-x-4 overflow-auto">
            {timeslots
              .filter((timeslot) => timeslot.isAvailable)
              .map((timeslot) => (
                <button
                  key={timeslot._id}
                  className={`flex-1 p-4 rounded-lg text-center text-black font-medium focus:outline-none ${
                    selectedTimeslot && selectedTimeslot._id === timeslot._id
                      ? "bg-primary-700"
                      : "bg-neutral-400"
                  }`}
                  onClick={() => handleSelectTimeslot(timeslot)}
                >
                  {timeslot.slotTime}
                </button>
              ))}
          </div>
        </div>
        <div className="pt-4">
          <div
            className="flex items-center p-4 mb-4 text-sm text-neutral-900 bg-neutral-50 rounded-lg"
            role="alert"
          >
            <span className="flex-1">
              <p className="font-semibold inline">Note:</p> Free cancellation
              until 12 hours before the start of your booking.{" "}
            </span>
          </div>
        </div>

        <div className="mt-4">
          <label
            htmlFor="services"
            className="block text-lg font-semibold text-white mt-4"
          >
            Add-on Services
          </label>{" "}
          <div className="flex overflow-x-auto">
            <div className="grid grid-flow-col auto-cols-max gap-6">
              {services.map((service) => (
                <ServiceCard key={service._id} service={service} />
              ))}
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label
            htmlFor="products"
            className="block text-lg font-semibold text-white mt-4"
          >
            Add-on Products
          </label>{" "}
          <div className="flex overflow-x-auto">
            <div className="grid grid-flow-col auto-cols-max gap-6">
              {products.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-2/6 text-white p-2 rounded-lg bg-slate-950">
        <div>
          <label
            htmlFor="booking-details"
            className="block text-lg font-semibold text-white mt-4"
          >
            Booking Details
          </label>{" "}
          <label htmlFor="address" className="w-1/5 text-md text-white mt-4">
            Address:
          </label>
          <div className="flex items-center mt-4 space-x-4">
            <select
              id="address"
              value={selectedAddress?._id}
              className="my-2 block w-1/3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              onChange={handleAddressChange}
            >
              {addressInfo?.map((address) => (
                <option key={address._id} value={address._id}>
                  {address.name}
                </option>
              ))}
            </select>
            <ButtonPrimary
              className="mt-1 whitespace-nowrap"
              onClick={openAddressModal}
            >
              Add Address
            </ButtonPrimary>
            {isAddressModalOpen && (
              <Modal isOpen={isAddressModalOpen} onClose={closeAddressModal}>
                {" "}
                {/* Increase max width here */}
                <div className="m-4">
                  <MapWithAutocomplete onAddressSelect={handleAddressSelect} />
                  <AddressForm
                    initialValues={newAddress}
                    onSubmit={handleSaveNewAddress}
                    onCancel={handleCancelNewAddress}
                  />
                </div>
              </Modal>
            )}
          </div>
        </div>
        <div className="ml-2">
          {selectedAddress && (
            <div>
              <p>
                {selectedAddress.street}, &nbsp;{selectedAddress.city},
              </p>
              <p>{selectedAddress.postalCode}</p>
              <p>{selectedAddress.country}</p>
            </div>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="cart"
            className="block text-lg font-semibold text-white mt-4"
          >
            Your Cart
          </label>
          <div>
            <h2 className="m-0 p-0 font-bold " style={{ color: "#e67e05" }}>
              Package
            </h2>
            {packagesInCart.map((packages) => (
              <div key={packages.id} className="flex justify-between">
                <p style={{ maxWidth: "70%" }}>
                  {packages.name} x {packages.quantity}
                </p>
                <p>AED &nbsp;{packages.price}</p>
              </div>
            ))}
          </div>

          <div>
            <h2 className="m-0 p-0 font-bold" style={{ color: "#e67e05" }}>
              Services
            </h2>
            {servicesInCart.map((service) => (
              <div key={service.id} className="flex justify-between">
                <p style={{ maxWidth: "70%" }}>
                  {service.name} x {service.quantity}
                </p>
                <p>AED &nbsp;{service.price}</p>
              </div>
            ))}
          </div>

          <div>
            <h2 className="m-0 p-0 font-bold" style={{ color: "#e67e05" }}>
              Products
            </h2>
            {productsInCart.map((product) => (
              <div key={product.id} className="flex justify-between">
                <p style={{ maxWidth: "70%" }}>
                  {product.name} x {product.quantity}
                </p>
                <p> AED &nbsp; {product.price}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-between mb-4">
          <h2 className="m-0 p-0 font-bold" style={{ color: "#e67e05" }}>
            Subtotal
          </h2>
          <p className="flex justify-end font-semibold">
            AED &nbsp;{calculateSubtotal(items)}
          </p>
        </div>

        <div className="mb-4">
          <input
            type="text"
            placeholder="Enter promo code"
            value={promoCodeInput}
            onChange={(e) => setPromoCodeInput(e.target.value)}
            className="p-2 bg-white border border-gray-300 rounded mr-2 mb-4"
          />
          <button
            onClick={applyPromoCode}
            className="px-4 py-2 bg-green-500 text-white text-sm font-medium rounded hover:bg-green-600"
          >
            Apply
          </button>
        </div>
        <div>
          <h2 className="text-lg font-semibold mb-2">Payment Summary</h2>
          {discount > 0 && (
            <div>
              {" "}
              <div className="flex justify-between">
                <p>Subtotal:</p>
                <p> {subtotal}</p>
              </div>
              <div className="flex justify-between">
                <p>Promo Code Discount:</p>
                <p> {discount}</p>
              </div>
            </div>
          )}
          <div className="flex justify-between">
            <p>Total:</p>
            <p> {total}</p>
          </div>
        </div>
        <button
          onClick={handlePayment}
          className={`mt-4 px-4 py-2 bg-orange-500 text-white text-sm font-medium rounded hover:bg-orange-600 w-full ${
            !selectedTimeslot ||
            !selectedDate ||
            !selectedAddress ||
            !selectedVehicle
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          disabled={
            !selectedTimeslot ||
            !selectedDate ||
            !selectedAddress ||
            !selectedVehicle
          }
        >
          Proceed to Payment
        </button>
      </div>
    </div>
  );
};

export default CartScreen;
