import React from "react";

// Define the props based on standard button attributes to keep it flexible
export interface ButtonPrimaryProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  className = "",
  children,
  ...props
}) => {
  // Directly use the HTML button element with Tailwind CSS classes for styling
  return (
    <button
      className={`disabled:bg-opacity-70 bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 z-10 ${className}`}
      {...props}
    >
      {children}{" "}
    </button>
  );
};

export default ButtonPrimary;
