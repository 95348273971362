import React from "react";
import AboutUsImage from "images/aboutus-image.png";
import ServicesImage from "images/aboutus-services.jpeg";
import ProductsImage from "images/aboutus-product.jpeg";
const AboutUsScreen = () => {
  return (
    <section className="bg-slate-950 text-gray-100 py-32 min-h-screen">
      <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <h2 className="mb-12 text-4xl font-bold leadi text-center text-white sm:text-5xl">
          About Us - Carveel Auto Spa{" "}
        </h2>
        <p className="m-16">
          {" "}
          Welcome to Carveel Auto Spa, your premier destination for top-tier
          automotive care. Established in 2023, Carveel Auto Spa was founded
          with a passion for excellence and a commitment to delivering
          unparalleled service to our valued customers. Our mission is to
          transform every vehicle into a shining example of pristine perfection.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8 m-16">
          <div className="max-w-lg">
            <h2 className="text-3xl font-extrabold sm:text-4xl">Our Story</h2>
            <p className="mt-4 text-lg">
              Carveel Auto Spa was born out of a love for cars and a desire to
              create an oasis for car enthusiasts and everyday drivers alike. We
              understand that your vehicle is more than just a mode of
              transportation; it's a reflection of your personality and a
              significant investment. That's why we have dedicated ourselves to
              providing the highest quality services and products to keep your
              car looking and performing its best.
            </p>
          </div>
          <div className="mt-12 md:mt-0">
            <img
              src={AboutUsImage}
              className="object-cover rounded-lg shadow-md"
              alt="About Us"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8 m-16">
          <div className="mt-12 md:mt-0">
            <img
              src={ServicesImage}
              className="object-cover rounded-lg shadow-md"
              alt="About Us"
            />
          </div>
          <div className="max-w-lg">
            <h2 className="text-3xl font-extrabold sm:text-4xl">
              Our Services
            </h2>
            <p className="mt-4 text-lg">
              At Carveel Auto Spa, we offer a comprehensive range of services
              designed to meet all your car care needs. From full-service car
              washes and meticulous detailing to advanced paint correction and
              ceramic coating, we have the expertise and tools to handle it all.
              Our experienced technicians use only the finest products and
              techniques to ensure your car receives the care it deserves.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8 m-16">
          <div className="max-w-lg">
            <h2 className="text-3xl font-extrabold sm:text-4xl">
              Our Products
            </h2>
            <p className="mt-4 text-lg">
              In addition to our exceptional services, we offer a curated
              selection of premium car care products. Whether you're looking for
              the perfect car shampoo, a high-quality wax, or a complete
              detailing kit, you'll find everything you need in our product
              lineup. We choose products that are not only effective but also
              environmentally friendly, ensuring you can care for your car
              without harming the planet.
            </p>
          </div>
          <div className="mt-12 md:mt-0">
            <img
              src={ProductsImage}
              className="object-cover rounded-lg shadow-md"
              alt="About Us"
            />
          </div>
        </div>
        <h2 className=" text-2xl font-bold leadi text-center text-white sm:text-2xl">
          Join the Carveel Family{" "}
        </h2>
        <p className="mx-16">
          {" "}
          Whether you're a car enthusiast, a daily commuter, or a weekend
          warrior, Carveel Auto Spa is here to elevate your car care experience.
          Book us today to discover the difference that passion and expertise
          can make in your vehicle's appearance and performance. We look forward
          to welcoming you to the Carveel family and providing your car with the
          care it deserves.{" "}
        </p>
      </div>
    </section>
  );
};

export default AboutUsScreen;
