// components/ServiceCard.tsx
import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal"; // Make sure this path is correct
import { useAppDispatch, useAppSelector } from "app/hooks";
import { addItem, removeItem } from "features/cart/cartSlice";
export interface Service {
  _id: string;
  name: string;
  image: string;
  price: number;
  description: string;
}

interface ServiceCardProps {
  service: Service;
  className?: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ service }) => {
  const { _id, name, image, price, description } = service;
  const [modalOpen, setModalOpen] = useState(false);

  const itemsInCart = useAppSelector((state) => state.cart.items);
  const dispatch = useAppDispatch();

  const isItemInCart = itemsInCart.some((item) => item.id === _id);
  const buttonLabel = isItemInCart ? "Remove" : "Add to Cart";
  const buttonColor = isItemInCart ? "bg-primary-500" : "bg-secondary-800";

  const handleCartOperation = () => {
    if (isItemInCart) {
      dispatch(removeItem(_id));
    } else {
      dispatch(
        addItem({ id: _id, type: "Service", name, image, price, quantity: 1 })
      );
    }
  };

  const toggleModal = () => setModalOpen(!modalOpen);
  const truncatedName = name.length > 25 ? `${name.substring(0, 25)}...` : name;

  return (
    <div className="flex flex-col bg-neutral-800 shadow-md rounded-lg overflow-hidden h-full lg:w-64">
      <img
        src={
          image.startsWith("http")
            ? image
            : process.env.REACT_APP_API_URL + image
        }
        alt={name}
        className="w-full h-48 p-2 object-cover"
        onClick={toggleModal}
      />
      <div className="flex-1 p-4 flex flex-col justify-between">
        <h3
          className="text-lg font-semibold text-white mb-2"
          onClick={toggleModal}
        >
          {truncatedName}
        </h3>

        <div>
          <label className="block text-lg font-semibold text-white">
            AED {price}
          </label>
          <ButtonPrimary
            className={`${buttonColor}`}
            onClick={handleCartOperation}
          >
            {buttonLabel}
          </ButtonPrimary>
        </div>
      </div>
      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <div className="p-4">
          <img
            src={
              image.startsWith("http")
                ? image
                : process.env.REACT_APP_API_URL + image
            }
            alt={name}
            className="w-full h-48 object-cover"
            onClick={toggleModal}
          />
          <h2 className="text-xl font-bold p-2 text-white">{name}</h2>
          <p className="mb-4 text-white">{description}</p>
          <p className="text-lg font-semibold text-white">Price: AED {price}</p>
        </div>
      </Modal>
    </div>
  );
};

export default ServiceCard;
