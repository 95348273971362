import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import { addItem, removeItem, clearCart } from "features/cart/cartSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";

export interface Detail {
  title: string;
  content: string;
}

export interface Package {
  _id: string;
  name: string;
  price: number;
  details: Detail[];
  carType: string;
}

export interface PackageCardProps {
  className?: string;
  taxonomy: Package;
  isSelected?: boolean;
  onSelect?: () => void;
}

function determineBackground(packageName: string): string {
  switch (packageName.toLowerCase()) {
    case "silver":
      return "bg-gradient-to-r from-[#061e45] to-[#0d6ca9]";
    case "gold":
      return "bg-gradient-to-r from-[#3e0c5c] to-[#904b80]";
    case "platinum":
      return "bg-gradient-to-r from-[#7e1d25] to-[#c95d52]";
    default:
      return "bg-gray-200"; // A neutral default background
  }
}

const PackageCard: FC<PackageCardProps> = ({
  className = "",
  taxonomy,
  isSelected,
  onSelect,
}) => {
  const { _id, name, price, details } = taxonomy;
  const [modalOpen, setModalOpen] = useState(false);
  const itemsInCart = useAppSelector((state) => state.cart.items);
  const dispatch = useAppDispatch();

  const isItemInCart = itemsInCart.some((item) => item.id === _id);
  const buttonLabel = isItemInCart ? "Remove" : "Select Package";
  const buttonColor = isItemInCart ? "bg-primary-500" : "bg-secondary-800";

  const handleCartOperation = () => {
    if (isItemInCart) {
      dispatch(removeItem(_id));
    } else {
      dispatch(clearCart());
      dispatch(addItem({ id: _id, type: "Package", name, price, quantity: 1 }));
    }
  };
  const toggleModal = () => setModalOpen(!modalOpen);

  const backgroundClass = determineBackground(name);

  const textShadowClass = "text-shadow-md";

  return (
    <div
      className={`nc-CardCategory6 relative flex flex-col justify-between items-center w-full h-full rounded-2xl p-6 ${backgroundClass} ${
        isItemInCart ? "ring-4 ring-yellow-400" : ""
      } ${className}`}
      data-nc-id="CardCategory6"
    >
      <div onClick={toggleModal}>
        <h2
          className={`text-2xl lg:text-3xl text-white font-semibold mb-4 text-center ${textShadowClass}`}
        >
          {name}
        </h2>

        <ul
          className={`text-white text-opacity-90 py-2 px-4${textShadowClass}`}
        >
          {details.map((detail, index) => (
            <div key={index} className="pb-4">
              <li className="font-bold text-lg">{detail.title}</li>
              <li className="text-lg font-medium">
                {modalOpen
                  ? detail.content.substring(0, 60)
                  : `${detail.content.substring(0, 60)}...`}
                {detail.content.length > 30 ? (
                  <button onClick={toggleModal}>
                    <span className="underline py-4">Read More</span>
                  </button>
                ) : (
                  ""
                )}
              </li>
            </div>
          ))}
        </ul>
      </div>

      <div className="mt-auto w-full flex justify-between items-center text-white py-8">
        <span className={`text-3xl font-semibold ${textShadowClass}`}>
          {price} AED
        </span>
      </div>
      <ButtonPrimary
        className={`${buttonColor} p-4 text-lg font-semibold w-full rounded-lg`}
        onClick={handleCartOperation}
      >
        {buttonLabel}
      </ButtonPrimary>
      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <div className={`p-8 rounded-lg ${backgroundClass}`}>
          <h2 className="text-xl font-bold p-2 text-white">
            {name} Package Details
          </h2>
          {details.map((detail, index) => (
            //add detail.title
            <div key={index} className="text-white mb-2">
              {detail.title}
              <li className="text-white mb-2">{detail.content}</li>
            </div>
          ))}
          <div className="mt-4">
            <ButtonPrimary onClick={toggleModal}>Close</ButtonPrimary>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PackageCard;
