import React from "react";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

interface Partner {
  _id?: string;
  name: string;
  email: string;
  mobile: string;
  address?: string;
}

interface PartnerState {
  partners: Partner[];
  loading: boolean;
  error: string | null;
  successMessage: string | null;
  status: number | null;
}

const initialState: PartnerState = {
  partners: [],
  loading: false,
  error: null,
  successMessage: null,
  status: null,
};

export const fetchPartners = createAsyncThunk<
  Partner[],
  void,
  { rejectValue: string }
>("partners/fetchPartners", async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const response = await axiosInstance.get("/api/partner", config);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

export const createPartner = createAsyncThunk<
  Partner,
  Partner,
  { rejectValue: { message: string; status: number | null } }
>("partner/createPartner", async (partnerData, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const response = await axiosInstance.post(
      "/api/partner",
      partnerData,
      config
    );
    console.log(response);

    return response.data;
  } catch (error: any) {
    let errorMessage;
    let errorStatus = null;
    if (error.response) {
      errorMessage = error.response.data.message;
      errorStatus = error.response.status;
    } else {
      errorMessage = error.message;
    }
    return rejectWithValue({
      message: errorMessage,
      status: errorStatus,
    });
  }
});

const partnerSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {
    partnerCreated(state, action: PayloadAction<Partner>) {
      state.partners.push(action.payload);
      state.successMessage = "Partner created successfully"; // Set success message
    },
    clearSuccessMessage(state) {
      state.successMessage = null; // Clear success message
    },
    clearError(state) {
      state.error = null;
    },
    clearStatus(state) {
      state.status = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartners.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchPartners.fulfilled,
        (state, action: PayloadAction<Partner[]>) => {
          state.loading = false;
          state.partners = action.payload;
        }
      )
      .addCase(
        fetchPartners.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An error occurred";
        }
      )
      .addCase(createPartner.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        createPartner.fulfilled,
        (state, action: PayloadAction<Partner>) => {
          state.loading = false;
          state.partners.push(action.payload);
          state.successMessage = "Partner created successfully"; // Set success message
          state.status = 201;
        }
      )
      .addCase(
        createPartner.rejected,
        (
          state,
          action: PayloadAction<
            { message: string; status: number | null } | undefined
          >
        ) => {
          state.loading = false;
          state.error = action.payload
            ? action.payload.message
            : "An error occurred";
        }
      );
  },
});

export const { partnerCreated, clearSuccessMessage, clearError, clearStatus } =
  partnerSlice.actions;

export default partnerSlice.reducer;
