import React from "react";

const TermsAndConditonsScreen = () => {
  return (
    <section className="bg-slate-950 text-gray-100 py-16 min-h-screen">
      <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
        <h2 className="mb-12 text-4xl font-bold leadi text-center sm:text-5xl">
          Terms and Conditions
        </h2>

        <div className="container">
          <p>
            Please read these terms and conditions carefully before using Our
            Service.
          </p>
          <p>
            Interpretation and Definitions
            <br />
            Interpretation
            <br />
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <p>
            Definitions
            <br />
            For the purposes of these Terms and Conditions:
          </p>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditonsScreen;
