// ProductManager.tsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import Input from "shared/Input/Input"; // Assume this is already styled with Tailwind CSS
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  createProduct,
  deleteProduct,
  editProduct,
  fetchProducts,
} from "features/product/productSlice";
import { NewProduct, Product } from "data/types";

const ProductManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const { products, loading, error } = useAppSelector(
    (state) => state.products
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [productForm, setProductForm] = useState<NewProduct>({
    name: "",
    image: "", // Include the image property with an empty string
    price: 0,
    description: "",
  });

  // State to track the edited product
  const [editedProduct, setEditedProduct] = useState<Product | null>(null);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = event.target as HTMLInputElement; // Type assertion here
    const { name, value } = target;
    // Now TypeScript knows target could have a `files` property when it's an input
    if (target.type === "file") {
      const files = target.files;
      if (files) {
        setImageFile(files[0]);
      }
    } else {
      setProductForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // Function to open edit modal and populate data
  const openEditModal = (productToEdit: Product) => {
    // Populate the productForm state with data from the product to edit
    setProductForm({
      name: productToEdit.name,
      image: productToEdit.image, // Include the image property
      price: productToEdit.price,
      description: productToEdit.description,
    });

    setEditedProduct(productToEdit);
    setIsModalOpen(true);
  };

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const uploadFileHandler = async (): Promise<string> => {
    if (!imageFile) return "";
    const formData = new FormData();
    formData.append("image", imageFile);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axiosInstance.post<string>(
        "/api/upload/products",
        formData,
        config
      );
      return data; // Assuming your backend returns the URL of the uploaded image
    } catch (error) {
      console.error("Error uploading file:", error);
      return "";
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Check if we are editing an existing product
    if (editedProduct) {
      // Perform edit operation
      let imageUrl = productForm.image;
      if (imageFile) {
        imageUrl = await uploadFileHandler(); // Upload the new image if provided
      }
      dispatch(
        editProduct({
          productId: editedProduct._id,
          data: {
            ...productForm,
            image: imageUrl, // Update the image URL
          },
        })
      );
      dispatch(fetchProducts());
    } else {
      // Perform create operation
      let imageUrl = productForm.image;
      if (imageFile) {
        imageUrl = await uploadFileHandler();
      }
      dispatch(
        createProduct({
          ...productForm,
          image: imageUrl,
        })
      );
    }
    closeModal();
  };
  const deleteHandler = async (productId: string) => {
    // Confirm deletion with the user
    if (window.confirm("Are you sure you want to delete this product?")) {
      await dispatch(deleteProduct(productId));
      // Optionally, refetch products list after deletion
      dispatch(fetchProducts());
    }
  };

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className=" p-5">
      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Product</h2>
          <ButtonPrimary onClick={openModal} className="z-10">
            Create Product
          </ButtonPrimary>
        </div>
        <table className="min-w-full table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2">Image</th>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Price</th>
              <th className="px-4 py-2">Description</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((productItem: Product) => (
              <tr key={productItem._id}>
                <td className="border px-4 py-2">
                  <img
                    src={
                      process.env.REACT_APP_API_URL +
                      productItem.image +
                      `?t=${new Date().getTime()}`
                    }
                    alt={productItem.name}
                    className="h-10 w-10"
                  />
                </td>
                <td className="border px-4 py-2">{productItem.name}</td>
                <td className="border px-4 py-2">{productItem.price}</td>
                <td className="border px-4 py-2">{productItem.description}</td>
                <td className="border px-4 py-2">
                  <button
                    className="text-blue-500 hover:text-blue-700 mr-2"
                    onClick={() => openEditModal(productItem)} // Open edit modal with product data
                  >
                    Edit
                  </button>
                  <button
                    className="text-red-500 hover:text-red-700"
                    onClick={() => deleteHandler(productItem._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && <p>Loading products...</p>}
        {error && <p className="text-red-500">Error: {error}</p>}
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <form onSubmit={handleSubmit}>
            <div className="p-4">
              <h2 className="text-lg font-semibold mb-4">Create Product</h2>
              {/* Name input */}
              <div className="mb-4">
                <label className="block mb-2">Name</label>
                <Input
                  name="name"
                  placeholder="Product Name"
                  value={productForm.name}
                  onChange={handleInputChange}
                />
              </div>
              {/* Price input */}
              <div className="mb-4">
                <label className="block mb-2">Price</label>
                <Input
                  name="price"
                  type="number"
                  placeholder="Product Price"
                  value={productForm.price}
                  onChange={handleInputChange}
                />
              </div>
              {/* Image input - assuming URL for simplicity */}
              <div className="mb-4">
                <label className="block mb-2">Image URL</label>
                <Input
                  name="image"
                  placeholder="Upload Image"
                  type="file"
                  onChange={handleInputChange}
                />
              </div>
              {/* Description textarea */}
              <div className="mb-4">
                <label className="block mb-2">Description</label>
                <textarea
                  name="description"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Product Description"
                  value={productForm.description}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <ButtonPrimary type="submit">Submit</ButtonPrimary>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default ProductManager;
