// src/features/user/userSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { Address, VehicleInformation } from "../../data/types";

interface User {
  _id: string; // Ensure _id is defined and of type string
  email: string;
  fullName?: string;
  token: string;
  isAdmin?: boolean;
  addresses?: Address[];
  vehicleInformation?: VehicleInformation[];
  phoneNumber?: string;
}
//Now i'm changing

interface UserResponse {
  _id?: string;
  email: string;
  fullName?: string;
  token: string;
  isAdmin?: boolean;
  addresses?: Address[];
  vehicleInformation?: VehicleInformation[];
  phoneNumber?: string;
}

interface UserState {
  loading: boolean;
  addressInfo: Address[] | null; // Rename userInfo to addressInfo
  vehicleInfo: VehicleInformation[] | null; // Add a new state slice for vehicle information
  userInfo: UserResponse | null;
  error: string | null;
  users: User[]; // Add this to store the fetched users list
}

interface UserProfileUpdatePayload {
  email?: string; // Email might not always be updated
  addresses?: Address[];
  phoneNumber?: string;
  password?: string;
  fullName?: string;
  vehicleInformation?: VehicleInformation[];
}

const initialState: UserState = {
  loading: false,
  addressInfo: null, // Initialize addressInfo to null
  vehicleInfo: null, // Initialize vehicleInfo to null
  userInfo: null,
  error: null,
  users: [], // Ensure this is included in your initialState
};
interface RegisterPayload {
  fullName: string;
  email: string;
  password: string;
}
interface LoginPayload {
  email: string;
  password: string;
}

interface UpdateAdminStatusPayload {
  userId: string;
  isAdmin: boolean;
}

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const fetchUsers = createAsyncThunk<
  User[],
  undefined,
  { rejectValue: string }
>("users/fetchUsers", async (_, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token found");
    }
    const response = await axiosInstance.get("/api/users", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || error.message || "An error occurred";
    return rejectWithValue(errorMessage);
  }
});

export const fetchUserProfile = createAsyncThunk<
  User,
  undefined,
  { rejectValue: string }
>("user/fetchUserProfile", async (_, { getState, rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token found");
    }
    const response = await axiosInstance.get<User>("/api/users/profile", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const fetchVehicle = createAsyncThunk<
  VehicleInformation[],
  undefined,
  { rejectValue: string }
>("user/fetchVehicleInformation", async (_, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token found");
    }
    const response = await axiosInstance.get<{
      vehicleInformation: VehicleInformation[];
    }>("/api/users/vehicle", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.vehicleInformation || [];
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const addNewAddress = createAsyncThunk<
  Address[],
  Address,
  { rejectValue: string }
>("user/addNewAddress", async (data, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token found");
    }
    const response = await axiosInstance.post("/api/users/address", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.addresses;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const addNewVehicle = createAsyncThunk<
  VehicleInformation[],
  VehicleInformation,
  { rejectValue: string }
>("user/addNewVehicle", async (data, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token found");
    }
    const response = await axiosInstance.post("/api/users/vehicle", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.vehicleInformation || [];
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const deleteAddress = createAsyncThunk<
  Address[],
  string,
  { rejectValue: string }
>("user/deleteAddress", async (addressId, { rejectWithValue }) => {
  try {
    console.log("addressId", addressId);
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token found");
    }
    const response = await axiosInstance.delete(
      `/api/users/address/${addressId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.addresses;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const deleteVehicle = createAsyncThunk<
  VehicleInformation[],
  string,
  { rejectValue: string }
>("user/deleteVehicle", async (vehicleId, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token found");
    }
    const response = await axiosInstance.delete(
      `/api/users/vehicle/${vehicleId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.vehicleInformation || [];
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const fetchAddress = createAsyncThunk<
  Address[], // Change the return type to Address[]
  undefined,
  { rejectValue: string }
>("user/fetchAddress", async (_, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token found");
    }
    const response = await axiosInstance.get<{ addresses: Address[] }>(
      "/api/users/address",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.addresses; // Return the addresses array directly
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const updateUserProfile = createAsyncThunk<
  User,
  UserProfileUpdatePayload,
  { rejectValue: string }
>("user/updateUserProfile", async (data, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token found");
    }
    const response = await axiosInstance.put("/api/users/profile", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const updateAddress = createAsyncThunk<
  Address[],
  Address[],
  { rejectValue: string }
>("user/updateAddress", async (data, { rejectWithValue }) => {
  console.log("data", data);
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("No token found");
      return rejectWithValue("No token found");
    }

    const response = await axiosInstance.put("/api/users/address", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Return response.data.addresses instead of response.data
    return response.data.addresses;
  } catch (error: any) {
    console.error("Error updating address:", error);

    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const updateVehicle = createAsyncThunk<
  VehicleInformation[],
  VehicleInformation[],
  { rejectValue: string }
>("user/updateVehicle", async (data, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token found");
    }
    const response = await axiosInstance.put("/api/users/vehicle", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.vehicleInformation || [];
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const updateAddressDefault = createAsyncThunk<Address[], string>( // Add a second type parameter for the argument type
  "user/updateAddressDefault",
  async (addressId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return rejectWithValue("No token found");
      }
      const response = await axiosInstance.put(
        `/api/users/address/default/${addressId}`, // Include the addressId in the API endpoint
        null, // Pass null or an empty object as the request body, depending on your backend implementation
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.addresses; // Return the updated addresses array
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const updateVehicleDefault = createAsyncThunk<
  VehicleInformation[],
  string,
  { rejectValue: string }
>("user/updateVehicleDefault", async (vehicleId, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token found");
    }
    const response = await axiosInstance.put(
      `/api/users/vehicle/default/${vehicleId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.vehicleInformation || [];
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const updateUserAdminStatus = createAsyncThunk<
  User, // Assuming the return type of your API call matches the User type
  UpdateAdminStatusPayload, // Use the newly defined type here
  { rejectValue: string }
>(
  "users/updateAdminStatus",
  async ({ userId, isAdmin }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axiosInstance.put(
        `/api/users/${userId}`,
        { isAdmin },
        config
      );
      return data; // Assuming your backend returns the updated user object
    } catch (error: any) {
      // Correctly type the error as any for access to its properties
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Add this thunk to your packageSlice.ts
export const deleteUser = createAsyncThunk<
  string, // Assuming the backend returns the ID of the deleted package as confirmation
  string, // The type of the payload is the ID of the package to delete
  { rejectValue: string }
>("users/deleteUser", async (userId, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axiosInstance.delete(`/api/users/${userId}`, config);
    return userId; // Return the ID of the deleted package
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

export const register = createAsyncThunk<
  UserResponse,
  RegisterPayload,
  { rejectValue: { message: string; status: number | null } }
>(
  "user/register",
  async (
    { fullName, email, password }: RegisterPayload,
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axiosInstance.post(
        "/api/users/register",
        { fullName, email, password },
        config
      );
      return data;
    } catch (error: any) {
      let errorMessage;
      let errorStatus = null;

      if (error.response) {
        errorMessage = error.response.data.message;
        errorStatus = error.response.status;
      } else {
        errorMessage = error.message;
      }

      return rejectWithValue({
        message: errorMessage,
        status: errorStatus,
      });
    }
  }
);

export const login = createAsyncThunk<
  UserResponse,
  LoginPayload,
  { rejectValue: string }
>(
  "user/login",
  async ({ email, password }: LoginPayload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axiosInstance.post(
        "/api/users/login",
        { email, password },
        config
      );
      return data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        // Unauthorized: Wrong email or password
        return rejectWithValue("Wrong email or password");
      } else if (error.response && error.response.status === 404) {
        // Not Found: User doesn't exist
        return rejectWithValue("User doesn't exist, please register");
      } else {
        // Other errors
        return rejectWithValue("Login failed. Please try again.");
      }
    }
  }
);

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    // You can add reducers here for actions like logout
    logout(state) {
      state.userInfo = null;
      state.error = null;
      // Also, consider clearing the token from localStorage here if you're storing it
      localStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewAddress.pending, (state) => {
        state.loading = true;
      })

      .addCase(addNewAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.addressInfo = action.payload;
      })

      .addCase(addNewAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to add new address.";
      })

      .addCase(deleteAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.addressInfo = action.payload;
      })
      .addCase(deleteAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to delete address.";
      })

      .addCase(addNewVehicle.pending, (state) => {
        state.loading = true;
      })

      .addCase(addNewVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicleInfo = action.payload;
      })

      .addCase(addNewVehicle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to add new vehicle.";
      })

      .addCase(deleteVehicle.pending, (state) => {
        state.loading = true;
      })

      .addCase(deleteVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicleInfo = action.payload;
      })

      .addCase(deleteVehicle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to delete vehicle.";
      })

      .addCase(fetchAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.addressInfo = action.payload; // Update addressInfo directly
      })
      .addCase(fetchAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch address.";
      })
      .addCase(fetchVehicle.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicleInfo = action.payload; // Update vehicleInfo with the fetched vehicle information
      })
      .addCase(fetchVehicle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch vehicle information.";
      })
      .addCase(updateAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.addressInfo = action.payload; // Update addressInfo with the updated address information
      })
      .addCase(updateAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to update address.";
      })
      .addCase(updateVehicle.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicleInfo = action.payload; // Update vehicleInfo with the updated vehicle information
      })
      .addCase(updateVehicle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to update vehicle information.";
      })
      .addCase(updateAddressDefault.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAddressDefault.fulfilled, (state, action) => {
        state.loading = false;
        state.addressInfo = action.payload; // Update addressInfo with the updated addresses array
      })

      .addCase(updateAddressDefault.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to update default address.";
      })
      .addCase(updateVehicleDefault.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateVehicleDefault.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicleInfo = action.payload; // Update vehicleInfo with the updated vehicle information
      })
      .addCase(updateVehicleDefault.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to update default vehicle.";
      })

      .addCase(updateUserAdminStatus.fulfilled, (state, action) => {
        const index = state.users.findIndex(
          (user) => user._id === action.payload._id
        );
        if (index !== -1) {
          state.users[index] = action.payload; // Update the user in the state
        }
      })
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(
        fetchUsers.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An error occurred";
        }
      )
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.userInfo = action.payload;
      })

      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = false;
        // Use action.payload for the error message if available, or a default error message
        state.error = action.payload || "Failed to fetch user profile.";
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.userInfo = action.payload;
        // This assumes the updated user info is returned by your API
      })

      .addCase(register.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        register.fulfilled,
        (state, action: PayloadAction<UserResponse>) => {
          state.loading = false;
          state.userInfo = action.payload;
          // Optionally store the token in localStorage here as well
          localStorage.setItem("token", action.payload.token);
        }
      )
      .addCase(
        register.rejected,
        (
          state,
          action: PayloadAction<
            { message: string; status: number | null } | undefined
          >
        ) => {
          state.loading = false;
          state.error = action.payload
            ? action.payload.message
            : "An error occurred";
        }
      )
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        login.fulfilled,
        (state, action: PayloadAction<UserResponse>) => {
          state.loading = false;
          state.userInfo = action.payload;
          // Store the token in localStorage
          localStorage.setItem("token", action.payload.token);
        }
      )
      .addCase(
        login.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An error occurred";
        }
      );
  },
});

export default userSlice.reducer;
