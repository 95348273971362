import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { CarType } from "data/types";
import { Link } from "react-router-dom";

export interface CarCardProps {
  className?: string;
  taxonomy: CarType;
  isSelected: boolean;
  onSelect: () => void;
}

const CarCard: FC<CarCardProps> = ({
  className = "flex-1",
  taxonomy,
  isSelected,
  onSelect,
}) => {
  const { name, thumbnail } = taxonomy || {};
  return (
    <div
      className={`nc-CarCard relative flex w-full group rounded-2xl z-0 overflow-hidden ${
        isSelected ? "border-4 border-amber-700" : ""
      } ${className}`}
      data-nc-id="CarCard"
      onClick={onSelect}
      style={
        isSelected ? { boxShadow: "0 0 10px rgba(251, 191, 36, 0.7)" } : {}
      }
    >
      <div className="aspect-w-16 aspect-h-10 sm:aspect-h-12 xl:aspect-h-9 w-full h-0"></div>
      <NcImage
        src={thumbnail}
        className="object-cover absolute inset-0 w-full rounded-2xl group-hover:scale-105 transition-transform duration-300"
      />
      <div className="absolute bottom-0 inset-x-0 p-4 sm:p-6 text-white">
        <span className="absolute inset-0 bg-gradient-to-t from-black/60"></span>
        <h2 className={`relative text-lg lg:text-xl font-semibold text-center`}>
          {name}
        </h2>
      </div>
    </div>
  );
};

export default CarCard;
