// src/features/package/packageSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { NewService, Service, ServiceState } from "data/types";

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const initialState: ServiceState = {
  services: [],
  loading: false,
  error: null,
};

// Assuming your backend endpoints follow similar patterns to the user endpoints
export const fetchServices = createAsyncThunk<
  Service[],
  undefined,
  { rejectValue: string }
>("services/fetchServices", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get("/api/service");
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

export const createService = createAsyncThunk<
  Service,
  NewService,
  { rejectValue: string }
>("services/createService", async (serviceData, { rejectWithValue }) => {
  try {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");

    // Inside your createPackage thunk
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Access token from localStorage
      },
    };
    // Use `config` in your axios request

    const response = await axiosInstance.post(
      "/api/service",
      serviceData,
      config
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

// Add this thunk to your packageSlice.ts
export const deleteService = createAsyncThunk<
  string, // Assuming the backend returns the ID of the deleted package as confirmation
  string, // The type of the payload is the ID of the package to delete
  { rejectValue: string }
>("services/deleteService", async (serviceId, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axiosInstance.delete(
      `/api/service/${serviceId}`,
      config
    );
    return serviceId; // Return the ID of the deleted package
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});
export const editService = createAsyncThunk<
  string, // Assuming the backend returns the ID of the edited package as confirmation
  { serviceId: string; data: NewService },
  { rejectValue: string }
>("packages/editPackage", async ({ serviceId, data }, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Set Content-Type header for JSON data
      },
    };
    const response = await axiosInstance.put(
      `/api/service/${serviceId}`,
      data, // Send the updated package data
      config
    );
    return serviceId; // Return the ID of the edited package
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

const serviceSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchServices.fulfilled,
        (state, action: PayloadAction<Service[]>) => {
          state.loading = false;
          state.services = action.payload;
        }
      )
      .addCase(
        fetchServices.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An error occurred";
        }
      )

      .addCase(
        createService.fulfilled,
        (state, action: PayloadAction<Service>) => {
          // Optionally update state to include the new package
          state.services.push(action.payload);
        }
      )

      // Inside extraReducers of your packageSlice
      .addCase(
        deleteService.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.services = state.services.filter(
            (serviceItem) => serviceItem._id !== action.payload
          );
        }
      )
      .addCase(
        editService.fulfilled,
        (state, action: PayloadAction<string>) => {
          // Optionally, update the state if needed
        }
      );
    // Handle pending and rejected cases as needed

    // Handle pending and rejected cases as needed
  },
});

export default serviceSlice.reducer;
