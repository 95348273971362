// TimeslotManager.tsx
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchTimeslot,
  updateIsAvailable,
} from "features/timeslot/timeslotSlice";

export interface Timeslot {
  _id: string;
  slotTime: string;
  isAvailable: boolean;
}

const TimeslotManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const { timeslots, loading, error } = useAppSelector(
    (state) => state.timeslots
  );

  useEffect(() => {
    dispatch(fetchTimeslot());
  }, [dispatch]);

  const handleAvailabilityChange = (
    timeslotId: string,
    isAvailable: boolean
    // currentSlotTime: string // Assuming you have access to the current slot time
  ) => {
    dispatch(
      updateIsAvailable({
        timeslotId,
        data: { isAvailable: !isAvailable },
      })
    );
  };

  return (
    <div className="p-5">
      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Timeslots</h2>
        </div>
        <table className="min-w-full table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2">Time Slot</th>
              <th className="px-4 py-2">Available</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {timeslots.map((timeslot: Timeslot) => (
              <tr key={timeslot._id}>
                <td className="border px-4 py-2">{timeslot.slotTime}</td>
                <td className="border px-4 py-2">
                  {timeslot.isAvailable ? "Yes" : "No"}
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="checkbox"
                    checked={timeslot.isAvailable}
                    onChange={() =>
                      handleAvailabilityChange(
                        timeslot._id,
                        timeslot.isAvailable
                      )
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">Error: {error}</p>}
      </div>
    </div>
  );
};

export default TimeslotManager;
