// src/shared/Modal/Modal.tsx
import React, { useState, useEffect, ReactNode } from "react";
import ReactDOM from "react-dom";

interface ModalProps {
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
}

function useDelayedRender(isOpen: boolean): boolean {
  const [shouldRender, setShouldRender] = useState<boolean>(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (isOpen) {
      setShouldRender(true);
    } else if (!isOpen && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), 500); // Ensure delay matches your CSS transitions
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isOpen, shouldRender]);

  return shouldRender;
}

const Modal: React.FC<ModalProps> = ({ isOpen, children, onClose }) => {
  const shouldRender = useDelayedRender(isOpen);

  if (!shouldRender) return null;

  return ReactDOM.createPortal(
    <div
      className={`fixed inset-0 z-50 overflow-auto ${
        isOpen
          ? "bg-black bg-opacity-50"
          : "bg-black bg-opacity-0 pointer-events-none"
      } flex transition-opacity duration-300 ease-out`}
      onClick={onClose}
    >
      <div
        className="relative bg-neutral-800 w-full max-w-md m-auto flex-col flex rounded-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="absolute top-0 right-0 p-4">
          <button className="text-2xl text-white" onClick={onClose}>
            &times;
          </button>
        </span>
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;
