// components/BlogCard.tsx
import React from "react";
import { Link } from "react-router-dom";

interface BlogCardProps {
  id: number;
  title: string;
  summary: string;
  imageUrl: string;
}

const BlogCard: React.FC<BlogCardProps> = ({
  id,
  title,
  summary,
  imageUrl,
}) => {
  return (
    <Link to={`/blog/${id}`} className="block ">
      <div className=" bg-neutral-800 rounded-lg shadow overflow-hidden flex flex-col h-full">
        <img src={imageUrl} alt={title} className="w-full h-48 object-cover" />
        <div className="p-4">
          <h3 className="text-lg font-semibold">{title}</h3>
          <p className="mt-2 text-gray-600">{summary}</p>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
