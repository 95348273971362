import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useUser } from "../context/UserContext"; // Adjust path as needed
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { E164Number } from "libphonenumber-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface VerifyResponse {
  token: string; // Assuming the token is returned as a string
  // You can add other fields that might be returned from the backend
}

const PhoneLogin: React.FC = () => {
  const [phone, setPhone] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null); // State for error messages
  const [success, setSuccess] = useState<string | null>(null); // State for success messages
  const { setUpRecaptcha, loginWithPhone } = useUser();
  const [confirmationResult, setConfirmationResult] = useState<any>(null); //save confirmation result for otp verification
  const navigate = useNavigate();
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const handleSendOtp = async () => {
    try {
      // Ensure recaptcha element is present and valid
      const recaptchaContainer = document.getElementById("recaptcha");
      if (!recaptchaContainer) {
        throw new Error("Recaptcha container not found");
      }

      const recaptcha = new RecaptchaVerifier(auth, recaptchaContainer, {
        size: "invisible",
        callback: (response: any) => {},
        "expired-callback": () => {},
      });

      const confirmation = await signInWithPhoneNumber(auth, phone, recaptcha);
      console.log(confirmation);
      setConfirmationResult(confirmation);
      setIsOtpSent(true);
      setSuccess("OTP sent successfully.");
      setError(null);
    } catch (error) {
      console.error("Error sending OTP:", error);
      setError("Failed to send OTP. Please try again.");
      setSuccess(null);
    }
  };

  const handleVerifyOtp = async () => {
    if (!confirmationResult) {
      setError("Please send OTP first");
      return;
    }
    try {
      const result = await confirmationResult.confirm(otp);
      const idToken = await result.user.getIdToken();
      const userData = await verifyWithbackend(idToken, phone);

      if (userData?.token) {
        localStorage.setItem("token", userData.token);
      }

      loginWithPhone(otp);
      navigate("/");

      setSuccess("OTP verified successfully");
      setError(null);
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setError("Invalid OTP, please try again");
      setSuccess(null);
    }
  };

  const verifyWithbackend = async (
    idToken: string,
    phone: string
  ): Promise<VerifyResponse | null> => {
    try {
      const response = await axiosInstance.post<VerifyResponse>(
        "/api/users/verify-otp",
        {
          idToken,
          phone,
        }
      );

      console.log(`response in verify with backend:`, response);
      if (!response.data) throw new Error("Failed to verify OTP with backend");
      console.log("Backend verification successful", response.data);
      return response.data; // Return the verified data
    } catch (error) {
      console.error("Backend verification failed:", error);
      setError("OTP verification failed, please try again");
      return null; // Return null in case of error
    }
  };

  // const handleVerifyOtp = async () => {
  //   try {
  //     await loginWithPhone(otp);
  //     setSuccess("OTP verified successfully.");
  //     setError(null);
  //     // Redirect or perform additional actions on successful login
  //   } catch (error) {
  //     console.error("Error verifying OTP:", error);
  //     setError("Invalid OTP. Please try again.");
  //     setSuccess(null);
  //   }
  // };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="p-6 rounded-lg shadow-md w-full max-w-sm">
        <h2 className="text-xl font-semibold mb-4 text-center">Phone Login</h2>
        <div id="recaptcha" className="mb-4"></div>
        <PhoneInput
          inputStyle={{ color: "#010101" }}
          country={"ae"} // UAE as default country
          value={phone}
          onChange={(phone) => setPhone("+" + phone)}
        />
        <button
          onClick={handleSendOtp}
          className="mt-8 bg-blue-500 text-white py-2 px-4 rounded mb-4 w-auto"
        >
          Send OTP
        </button>

        {isOtpSent && (
          <div>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="border border-gray-300 p-2 rounded mb-4 w-full"
            />
            <button
              onClick={handleVerifyOtp}
              className="bg-green-500 text-white py-2 px-4 rounded w-full"
            >
              Verify OTP
            </button>
          </div>
        )}

        {error && <p className="text-red-500 mt-4">{error}</p>}
        {success && <p className="text-green-500 mt-4">{success}</p>}
      </div>
    </div>
  );
};

export default PhoneLogin;
