import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  FC,
  useEffect,
} from "react";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
  onAuthStateChanged,
  ConfirmationResult,
  Auth,
} from "firebase/auth";
import { auth } from "../firebaseConfig"; // Ensure this is the correct path to your Firebase config

declare global {
  interface Window {
    _recaptchaVerifier?: RecaptchaVerifier;
    _confirmationResult?: ConfirmationResult;
    _captchaWidgetId?: number;
    grecaptcha?: {
      reset: (widgetId: number) => any;
    };
  }
}
interface User {
  email?: string;
  phoneNumber?: string;
  _id?: string;
  token?: string;
}

interface UserContextProps {
  user: User | null;
  login: (userData: User) => void;
  logout: () => void;
  setUpRecaptcha: (phoneNumber: string) => Promise<void>;
  loginWithPhone: (otp: string) => Promise<void>;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier | null>(null);

  const [confirmationResult, setConfirmationResult] = useState<any>(null);

  const login = (userData: User) => {
    localStorage.setItem("user", JSON.stringify(userData));
    setUser(userData);
  };

  const logout = async () => {
    await signOut(auth);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setUser(null);
  };

  const setUpRecaptcha = async (phoneNumber: string) => {
    try {
      // Check if reCAPTCHA is already initialized
      if (!window._recaptchaVerifier) {
        window._recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          { size: "invisible" }
        );
        await window._recaptchaVerifier.render();
      } else {
        // Reset the existing reCAPTCHA instance
        window._recaptchaVerifier.clear();
        await window._recaptchaVerifier.render();
      }

      // Proceed with phone number sign-in
      const result = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        window._recaptchaVerifier
      );

      setConfirmationResult(result); // Store the confirmation result for OTP verification
    } catch (error) {
      console.error("Error during OTP setup:", error);
      throw error; // Re-throw error for handling in the component
    }
  };

  const loginWithPhone = async (otp: string) => {
    if (confirmationResult) {
      const result = await confirmationResult.confirm(otp);
      const userData: User = {
        email: result.user.email || "", // Email might be empty if using phone login
        phoneNumber: result.user.phoneNumber || "", // This ensures phoneNumber is set
        _id: result.user.uid,
        token: await result.user.getIdToken(), // Fetch token using getIdToken
      };
      // Save user data to localStorage and update state
      localStorage.setItem("user", JSON.stringify(userData));
      setUser(userData);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const token = await currentUser.getIdToken(); // Wait for the token to be resolved
          setUser({
            email: currentUser.email || "",
            phoneNumber: currentUser.phoneNumber || "",
            _id: currentUser.uid, // Use UID if available
            token, // Set the resolved token
          });
          // localStorage.setItem(
          //   "user",
          //   JSON.stringify({
          //     email: currentUser.email || "",
          //     phoneNumber: currentUser.phoneNumber || "",
          //     _id: currentUser.uid, // Use UID if available
          //     token, // Set the resolved token
          //   })
          // );
        } catch (error) {
          console.error("Error fetching token:", error);
        }
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider
      value={{ user, login, logout, setUpRecaptcha, loginWithPhone }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
