import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import ServiceCard from "components/ServiceCard"; // Assuming you have a ServiceCard component
import { fetchServices } from "features/service/serviceSlice";

const Services: React.FC = () => {
  const dispatch = useAppDispatch();
  const { services, loading, error } = useAppSelector(
    (state) => state.services
  );

  useEffect(() => {
    dispatch(fetchServices());
  }, [dispatch]);

  if (loading) return <div>Loading services...</div>;
  if (error) return <div>Error loading services: {error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-semibold mb-6">Services</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
        {services.map((service) => (
          <ServiceCard key={service._id} service={service} className="m-2 " />
        ))}
      </div>
    </div>
  );
};

export default Services;
