import React, { useEffect, useState } from "react";
import axios from "axios";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  clearSuccessMessage,
  createPartner,
  clearError,
} from "features/partner/partnerSlice";
import { Bounce, ToastContainer, toast } from "react-toastify";

const Partner: React.FC = () => {
  const dispatch = useAppDispatch();
  const { partners, loading, error, successMessage } = useAppSelector(
    (state) => state.partners
  );
  if (successMessage) {
    toast("Your details are sent to Carveel, You'll be contacted shortly!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
  });

  useEffect(() => {
    // Clear success message on component mount
    return () => {
      dispatch(clearSuccessMessage());
      dispatch(clearError());
    };
  }, [dispatch]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await dispatch(createPartner(formData));

    console.log(formData);
  };

  return (
    <div className="container mx-auto p-4 max-w-4xl">
      <div>
        <h1 className="text-4xl font-semibold py-4">Become a Partner</h1>
        <p className="mt-4 ">Join our network of trusted service providers!</p>
        <p className="mt-4 ">
          If you offer car services and want to expand your reach, we invite you
          to partner with us. By joining our network, you'll have the
          opportunity to connect with more customers and grow your business.
        </p>
        <p className="mt-2 ">
          Please fill out the form below with your details, and we will get in
          touch with you shortly.
        </p>
        <p className="mt-2 font-bold ">
          Partner with us today and take your business to the next level!
        </p>
      </div>
      <p className="my-4 font-semibold ">Contact Information:</p>
      <form onSubmit={handleSubmit} className="max-w-2xl">
        <div className="mb-4">
          <label className="block ">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block ">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block ">Phone Number</label>
          <input
            type="text"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block ">Address</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded w-full"
          />
        </div>
        {error && <div className="error-message text-red-600">{error}</div>}

        <ButtonPrimary
          type="submit"
          className="bg-blue-500 text-white p-2 rounded w-full"
        >
          Submit
        </ButtonPrimary>
      </form>
      <div className="my-8">
        <p className="text-2xl font-semibold my-4">Why Partner with Us?</p>
        <ul className="list-disc list-inside mt-2">
          <li>Access to a broader customer base</li>
          <li>Increased visibility for your services</li>
          <li>Collaborative growth opportunities</li>
          <li>Dedicated support from our team</li>
        </ul>
        <p className="mt-4">We look forward to collaborating with you!</p>
      </div>
    </div>
  );
};

export default Partner;
