import React, { useEffect, useState } from "react";
import axios from "axios";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import Input from "shared/Input/Input"; // Assuming this is styled with Tailwind CSS
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchUsers,
  deleteUser,
  updateUserAdminStatus,
  fetchUserProfile,
} from "features/user/userSlice";
import { Address } from "data/types";

interface VehicleInformation {
  make: string;
  model: string;
  year: number | undefined;
  licensePlateNumber: string;
  isDefault?: boolean;
}

interface User {
  _id: string;
  email: string;
  fullName?: string;
  token: string;
  isAdmin?: boolean;
  addresses?: Address[];
  vehicleInformation?: VehicleInformation[];
  phoneNumber?: string;
}

const UserManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const { users, userInfo, loading, error } = useAppSelector(
    (state) => state.users
  );
  console.log("userInfo", userInfo);
  console.log(users);
  const currentUser = userInfo ? userInfo._id : "";
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [userForm, setUserForm] = useState({
    email: "",
    password: "",
    isAdmin: false,
  });

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchUserProfile());
  }, [dispatch]);
  const handleAdminChange = (userId: string, isAdmin: boolean) => {
    if (currentUser && userInfo && userId !== currentUser) {
      dispatch(updateUserAdminStatus({ userId, isAdmin }));
    } else {
      console.log("You cannot change your own admin status.");
    }
  };

  return (
    <div className="p-5">
      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Users</h2>
        </div>
        <table className="min-w-full table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2">Email</th>
              <th className="px-4 py-2">Admin</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user: User) => (
                <tr key={user._id}>
                  <td className="border px-4 py-2">{user.email}</td>
                  <td className="border px-4 py-2">
                    <input
                      type="checkbox"
                      checked={user.isAdmin}
                      onChange={(e) =>
                        handleAdminChange(user._id, e.target.checked)
                      }
                    />
                  </td>
                  <td className="border px-4 py-2">
                    {/* Here you could add buttons for editing or updating user admin status */}
                    <button
                      className="text-red-500 hover:text-red-700"
                      onClick={() => dispatch(deleteUser(user._id))}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">Error: {error}</p>}
      </div>
    </div>
  );
};

export default UserManager;
