// components/BlogPost.jsx
import React from "react";
import { useParams } from "react-router-dom";
import blogs from "../data/blogs"; // Ensure this path matches your actual blogs data location

const BlogPost = () => {
  let { id } = useParams();
  const blog = blogs.find((blog) => blog.id.toString() === id);

  if (!blog) {
    return (
      <div className="text-center py-10">
        <p className="text-xl text-white">Blog post not found.</p>
      </div>
    );
  }

  return (
    <article className="max-w-6xl mx-auto px-4 py-16">
      <header className="mb-8">
        <h1 className="text-5xl font-bold text-center mb-4 text-white">
          {blog.title}
        </h1>
        <p className="text-xl text-center text-white">
          By John Doe | Jan 1, 2024
        </p>
        <div className="mt-4 flex justify-center">
          <img
            src={blog.imageUrl}
            alt={blog.title}
            className="rounded-lg object-cover h-auto max-h-96 w-full shadow-lg"
          />
        </div>
      </header>

      <div className="mt-8 prose prose-lg prose-indigo mx-auto w-128 text-white">
        {/* Assuming blog.content is rich text or Markdown that has been safely sanitized */}
        {blog.content.split("\n").map((paragraph, index) => (
          <p key={index} className="mb-6">
            {paragraph}
          </p>
        ))}
        {/* For Markdown content, you might use a library like 'react-markdown' to render it */}
      </div>

      <footer className="text-center mt-16">
        <p className="text-white ">Thank you for reading!</p>
      </footer>
    </article>
  );
};

export default BlogPost;
