import React from "react";

const Cancel: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-2xl text-red-500">
      <h1>Payment Cancelled</h1>
      <p>
        You have cancelled your payment. If this was a mistake, please go back
        and try again.
      </p>
    </div>
  );
};

export default Cancel;
