import { initializeApp } from "firebase/app";
import { getAuth, Auth } from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyBzMFQ0tVwxrgXMR7b8xdwEuIrkt_Y3SOc",
  authDomain: "carveel-carwash.firebaseapp.com",
  projectId: "carveel-carwash",
  storageBucket: "carveel-carwash.appspot.com",
  messagingSenderId: "25831624167",
  appId: "1:25831624167:web:9051d77e06b98985764142",
  measurementId: "G-48X6FS68HN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth: Auth = getAuth(app); // Ensure that `auth` is typed as `Auth`
