import React from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchOrders,
  getMyOrders,
  updateOrderStatus,
} from "features/orders/orderSlice";
import { useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonDanger from "shared/Button/ButtonDanger";
import Modal from "shared/Modal/Modal";

const MyOrders = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);

  const dispatch = useAppDispatch();
  const { orders, loading, error } = useAppSelector((state) => state.orders);

  useEffect(() => {
    dispatch(getMyOrders());
  }, [dispatch]);

  const handleOrderDetails = (orderId: string) => {
    const order = orders.find((order: any) => order._id === orderId);
    setSelectedOrder(order);
    setShowModal(true);
  };
  const cancelBooking = (orderId: string) => {
    dispatch(updateOrderStatus({ orderId, status: "Cancelled" }));
    setShowModal(false);
  };

  //display orders in table
  return (
    <div className="p-5">
      <h1 className="container text-xl ">My Orders</h1>
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <table className="min-w-full table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2">ID</th>
                <th className="px-4 py-2">DATE</th>
                <th className="px-4 py-2">TOTAL</th>
                <th className="px-4 py-2">STATUS</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order: any) => (
                <tr key={order._id}>
                  <td className="border px-4 py-2">{order._id}</td>
                  <td className="border px-4 py-2">
                    {order.createdAt ? order.createdAt.substring(0, 10) : "N/A"}
                  </td>
                  <td className="border px-4 py-2">AED &nbsp;{order.total}</td>
                  <td
                    className={`border px-4 py-2 ${
                      order.status === "Pending"
                        ? "bg-yellow-500"
                        : order.status === "Paid"
                        ? "bg-green-500"
                        : order.status === "Completed"
                        ? "bg-green-800"
                        : "bg-gray-500"
                    }`}
                  >
                    {order.status}
                  </td>

                  <td className="border px-4 py-2">
                    <ButtonPrimary
                      onClick={() => handleOrderDetails(order._id)}
                    >
                      View Details
                    </ButtonPrimary>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showModal && (
        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <div className="p-4">
            <p className="text-white">
              <span className="font-bold">Order ID:</span> {selectedOrder._id}
            </p>
            <p className="text-white">
              <span className="font-bold">Customer Name:</span>{" "}
              {selectedOrder.user && selectedOrder.user.name}
            </p>
            <p className="text-white">
              <span className="font-bold">Total Price:</span> AED &nbsp;
              {selectedOrder.total}
            </p>
            <p className="text-white">
              <span className="font-bold">Status:</span> {selectedOrder.status}
            </p>
            <p className="text-white">
              <span className="font-bold">Payment Method:</span>{" "}
              {selectedOrder.paymentMethod}
            </p>
            <p className="text-white">
              <span className="font-bold">Payment Status:</span>{" "}
              {selectedOrder.isPaid ? "Paid" : "Not Paid"}
            </p>
            <p className="text-white">
              <span className="font-bold">Delivery Status:</span>{" "}
              {selectedOrder.isDelivered ? "Delivered" : "Not Delivered"}
            </p>
            <div className="flex justify-around">
              <ButtonDanger
                onClick={() => cancelBooking(selectedOrder._id)}
                className="mt-4 mx-1"
              >
                Cancel Booking
              </ButtonDanger>
              <ButtonPrimary
                onClick={() => setShowModal(false)}
                className="mt-4"
              >
                Close
              </ButtonPrimary>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MyOrders;
