import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const PaymentScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <div>
      <h1>PaymentScreen</h1>
      <Elements
        stripe={loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`)}
      ></Elements>
    </div>
  );
};

export default PaymentScreen;
