// Blog page
import React from "react";
import BlogCard from "./BlogCard"; // Adjust the path according to your file structure
import blogs from "../data/blogs"; // Import centralized data

const Blog = () => {
  return (
    <div className="container mx-auto px-4 py-8 min-h-100">
      <h2 className="text-2xl font-semibold mb-6">Latest Blog Posts</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {blogs.map((blog) => (
          <BlogCard
            key={blog.id}
            id={blog.id}
            title={blog.title}
            summary={blog.summary}
            imageUrl={blog.imageUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default Blog;
