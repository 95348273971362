// src/features/product/productSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { NewProduct, Product, ProductState } from "data/types";

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const initialState: ProductState = {
  products: [],
  loading: false,
  error: null,
};

// Assuming your backend endpoints follow similar patterns to the user endpoints
export const fetchProducts = createAsyncThunk<
  Product[],
  undefined,
  { rejectValue: string }
>("products/fetchProducts", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get("/api/product");
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

export const createProduct = createAsyncThunk<
  Product,
  NewProduct,
  { rejectValue: string }
>("products/createProduct", async (productData, { rejectWithValue }) => {
  try {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");

    // Inside your createProduct thunk
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Access token from localStorage
      },
    };
    // Use `config` in your axios request

    const response = await axiosInstance.post(
      "/api/product",
      productData,
      config
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

// Add this thunk to your productSlice.ts
export const deleteProduct = createAsyncThunk<
  string, // Assuming the backend returns the ID of the deleted product as confirmation
  string, // The type of the payload is the ID of the product to delete
  { rejectValue: string }
>("products/deleteProduct", async (productId, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axiosInstance.delete(
      `/api/product/${productId}`,
      config
    );
    return productId; // Return the ID of the deleted product
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});
export const editProduct = createAsyncThunk<
  string, // Assuming the backend returns the ID of the edited product as confirmation
  { productId: string; data: NewProduct },
  { rejectValue: string }
>("products/editProduct", async ({ productId, data }, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Set Content-Type header for JSON data
      },
    };
    const response = await axiosInstance.put(
      `/api/product/${productId}`,
      data, // Send the updated product data
      config
    );
    return productId; // Return the ID of the edited product
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchProducts.fulfilled,
        (state, action: PayloadAction<Product[]>) => {
          state.loading = false;
          state.products = action.payload;
        }
      )
      .addCase(
        fetchProducts.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An error occurred";
        }
      )

      .addCase(
        createProduct.fulfilled,
        (state, action: PayloadAction<Product>) => {
          // Optionally update state to include the new product
          state.products.push(action.payload);
        }
      )

      // Inside extraReducers of your productSlice
      .addCase(
        deleteProduct.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.products = state.products.filter(
            (productItem) => productItem._id !== action.payload
          );
        }
      )
      .addCase(
        editProduct.fulfilled,
        (state, action: PayloadAction<string>) => {
          // Optionally, update the state if needed
        }
      );
    // Handle pending and rejected cases as needed

    // Handle pending and rejected cases as needed
  },
});

export default productSlice.reducer;
